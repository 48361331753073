<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="dataList" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">

            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="
              p-3
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            ">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
                <div class="btn-group ml-5">
                    <vs-button size="small" color="success" type="border"
                        @click="handleExport('excel')">Excel</vs-button>
                </div>
            </template>
            <template slot="thead">
                <vs-th sort-key="asset">Asset</vs-th>
                <vs-th sort-key="cost_center">Cost Center</vs-th>
                <vs-th sort-key="business_area">Bussines Area</vs-th>
                <vs-th sort-key="asset_class">Asset Class</vs-th>
                <vs-th sort-key="asset_description">Asset Description</vs-th>
                <vs-th sort-key="capitalized_on">Capitalized on</vs-th>
                <vs-th sort-key="base_unit_of_measure">Base Unit of Measure</vs-th>
                <vs-th sort-key="life_year_asset">Life Year Asset</vs-th>
                <vs-th sort-key="cum_acq_value">Cum.acq.value</vs-th>
                <vs-th sort-key="plnd_o_dep">Plnd.ODep</vs-th>
                <vs-th sort-key="accum_dep">Accum.dep.</vs-th>
                <vs-th sort-key="end_book_val">End book val</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="dataList[indextr].asset">
                        {{ data[indextr].asset }}
                    </vs-td>
                    <vs-td :data="tr.cost_center">
                        {{ tr.cost_center }}
                    </vs-td>
                    <vs-td :data="tr.business_area">
                        {{ tr.business_area }}
                    </vs-td>
                    <vs-td :data="tr.asset_class">
                        {{ tr.asset_class }}
                    </vs-td>
                    <vs-td :data="tr.asset_description">
                        {{ tr.asset_description }}
                    </vs-td>
                    <vs-td :data="tr.capitalized_on">
                        {{ dateFormat(tr.capitalized_on) }}
                    </vs-td>
                    <vs-td :data="tr.base_unit_of_measure">
                        {{ tr.base_unit_of_measure }}
                    </vs-td>
                    <vs-td :data="tr.life_year_asset">
                        {{ tr.life_year_asset }}
                    </vs-td>
                    <vs-td :data="tr.cum_acq_value">
                        {{ tr.cum_acq_value }}
                    </vs-td>
                    <vs-td :data="tr.plnd_o_dep">
                        {{ tr.plnd_o_dep }}
                    </vs-td>
                    <vs-td :data="tr.accum_dep">
                        {{ tr.accum_dep }}
                    </vs-td>
                    <vs-td :data="tr.end_book_val">
                        {{ tr.end_book_val }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="totalPage" v-model="setPage" />
    </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from 'vuex';

export default {
    props: {
        draw: {
            type: Number,
        },
        territoryIDs: {
            type: Array,
        },
        assetNumberIDs: {
            type: Array,
        },
        assetClassIDs: {
            type: Array,
        },
        costCenterIDs: {
            type: Array,
        },
    },
    computed: {
        ...mapState({
            dataList: (state) => state.reportAsset.dataList,
            totalPage: (state) => state.reportAsset.total_page
        }),
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
        };
    },
    methods: {
        ...mapActions({ getAssetReport: 'reportAsset/getAssetReport' }),
        tableDefaultState() {
            return {
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleExport(file) {
            this.$vs.loading();
            var fileTitle =
                "ARTRANS_REPORT_" +
                (this.territoryNames.length > 0
                    ? this.territoryNames.join("-")
                    : "all") +
                "_";

            if (this.startDocDate || this.endDocDate) {
                fileTitle +=
                    "DOC_DATE_" +
                    moment(this.startDocDate).format("YYYY-MM-DD") +
                    "_-_" +
                    moment(this.endDocDate).format("YYYY-MM-DD") +
                    "_";
            }

            if (this.startPostingDate || this.endPostingDate) {
                "POSTING_DATE_" +
                    moment(this.startPostingDate).format("YYYY-MM-DD") +
                    "_-_" +
                    moment(this.endPostingDate).format("YYYY-MM-DD") +
                    "_";
            }

            if (this.startDueDate || this.endDueDate) {
                "DUE_DATE_" +
                    moment(this.startDueDate).format("YYYY-MM-DD") +
                    "_-_" +
                    moment(this.endDueDate).format("YYYY-MM-DD") +
                    "_";
            }

            fileTitle +=
                "OPEN_KEY_DATE_" + moment(this.openKeyDate).format("YYYY-MM-DD");

            this.$http
                .get(this.baseUrl + "/export", {
                    params: {
                        territory_ids: this.territoryIDs,
                        start_posting_date: this.startPostingDate
                            ? moment(this.startPostingDate).format("YYYY-MM-DD")
                            : null,
                        end_posting_date: this.endPostingDate
                            ? moment(this.endPostingDate).format("YYYY-MM-DD")
                            : null,
                        start_doc_date: this.startDocDate
                            ? moment(this.startDocDate).format("YYYY-MM-DD")
                            : null,
                        end_doc_date: this.endDocDate
                            ? moment(this.endDocDate).format("YYYY-MM-DD")
                            : null,
                        start_due_date: this.startDueDate
                            ? moment(this.startDueDate).format("YYYY-MM-DD")
                            : null,
                        end_due_date: this.endDueDate
                            ? moment(this.endDueDate).format("YYYY-MM-DD")
                            : null,
                        open_key_date: this.openKeyDate
                            ? moment(this.openKeyDate).format("YYYY-MM-DD")
                            : null,
                        file: file,
                        title: fileTitle,
                    },
                })
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.status == "error") {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "success",
                            title: "Processing",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                });
        },
        dateFormat(val) {
            return moment(val).format("DD MMM YYYY");
        },
        getData() {
            if (this.draw > 0) {
                this.$vs.loading();
                this.getAssetReport({
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    territory: this.territoryIDs,
                    asset_number: this.assetNumberIDs,
                    asset_class: this.assetClassIDs,
                    cost_center: this.costCenterIDs,
                }).then(() => {
                    this.$vs.loading.close();
                }).catch(() => {
                    this.$vs.loading.close();
                })
            }
        },
    },
    watch: {
        draw() {
            this.getData();
        },
    }
}
</script>

<style scoped></style>