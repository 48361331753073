<template>
  <div>
    <vx-card>
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <vs-tabs>
          <vs-tab label="Asset Report">
            <!-- filter territory -->
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedTerritories"
                  :options="optionTerritories"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <!-- filter asset number -->
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Asset Number</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedAssetNumber"
                  :options="optionAssetNumber"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customAssetNumberLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <!-- filter asset class -->
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Asset Class</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedAssetClass"
                  :options="optionAssetClass"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customAssetClassLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <!-- filter cost center -->
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Cost Center</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedCostCenter"
                  :options="optionCostCenter"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button color="success" @click="handleDrawTable()"
                  >Get Data</vs-button
                >
              </div>
            </div>
            <div class="tab-text">
              <div class="vx-row">
                <div class="vx-col md:w-1/1 w-full mb-base">
                  <AssetTable
                    :draw="this.draw"
                    :territoryIDs="this.territoryIDs"
                    :assetNumberIDs="this.assetNumberIDs"
                    :assetClassIDs="this.assetClassIDs"
                    :costCenterIDs="this.costCenterIDs"
                  />
                </div>
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Export">
            <div class="tab-text">
              <DataTableExport />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex/dist/vuex.common.js';
import AssetTable from './AssetTable';
import DataTableExport from './DataTableExport.vue';

export default {
  components: {
    AssetTable,
    DataTableExport,
  },
  data() {
    return {
      selectedTerritories: [],
      selectedAssetNumber: [],
      selectedAssetClass: [],
      selectedCostCenter: [],
      optionTerritories: [],
      optionAssetNumber: [],
      optionAssetClass: [],
      optionCostCenter: [],
      territoryIDs: [],
      assetNumberIDs: [],
      assetClassIDs: [],
      costCenterIDs: [],
      territoryCodes: [],
      territoryNames: [],
      draw: 0,
    };
  },
  methods: {
    ...mapActions({
      getAssetNumber: 'reportAsset/getAssetNumber',
      getAssetClass: 'reportAsset/getAssetClass',
      getCostCenter: 'reportAsset/getCostCenter',
    }),
    handleDrawTable() {
      this.draw++;
    },
    customLabel(val) {
      if (val) {
        return val.code === '-' ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    customAssetNumberLabel(val) {
      if (val) {
        return val.name;
      }
    },
    customAssetClassLabel(val) {
      if (val) {
        return val.name;
      }
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/territory').then((resp) => {
        this.optionTerritories = resp.data.records;
      });
    },
  },
  mounted() {
    this.getTerritories();
    this.getAssetNumber();
    this.getAssetClass();
    this.getCostCenter();
  },
  watch: {
    selectedTerritories(val) {
      let territoryIDs = [];
      let territoryCodes = [];
      let territoryNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          territoryIDs.push(e.id);
          territoryCodes.push(e.code);
          territoryNames.push(e.name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryCodes = territoryCodes;
      this.territoryNames = territoryNames;
    },
    selectedAssetNumber(val) {
      let assetNumberIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          assetNumberIDs.push(e.id);
        }
      });

      this.assetNumberIDs = assetNumberIDs;
    },
    selectedAssetClass(val) {
      let assetClassIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          assetClassIDs.push(e.id);
        }
      });

      this.assetClassIDs = assetClassIDs;
    },
    selectedCostCenter(val) {
      let costCenterIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          costCenterIDs.push(e.id);
        }
      });

      this.costCenterIDs = costCenterIDs;
    },
    assetNumberList() {
      this.optionAssetNumber = this.assetNumberList;
    },
    assetClassList() {
      this.optionAssetClass = this.assetClassList;
    },
    costCenterList() {
      this.optionCostCenter = this.costCenterList;
    },
  },
  computed: {
    ...mapState({
      assetNumberList: (state) => state.reportAsset.assetNumberList,
      assetClassList: (state) => state.reportAsset.assetClassList,
      costCenterList: (state) => state.reportAsset.costCenterList,
    }),
  },
};
</script>

<style scoped></style>
